import Modal from 'react-bootstrap/Modal'
import classNames from 'classnames'
import styles from './OrderTypeSelection.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUtensils, faBagShopping, faPersonWalking, faArrowRight } from '@fortawesome/free-solid-svg-icons'

const OrderTypeSelection = ({
  orderTypeModal,
  setOrderTypeModal,
  restaurantName,
  takeAway,
  inHouse,
  selfService,
  t,
}: {
  orderTypeModal: any
  setOrderTypeModal: any
  restaurantName: string
  takeAway: boolean
  inHouse: boolean
  selfService: boolean
  t: any
}) => {
  return (
    <>
      <Modal show={orderTypeModal} onHide={() => setOrderTypeModal(false)} size="sm" centered={true} backdrop="static">
        <Modal.Body className={classNames('p-0', styles.orderTypeSelectionBackground)}>
          <div className="mt-2 p-2 text-center">
            <strong>
              {t('restaurant:welcome')} {restaurantName}
            </strong>
            <h6 className="mt-2">{t('restaurant:chooseOrderType')}</h6>
          </div>
          <div>
            {inHouse && (
              <button
                className={classNames('btn w-100 border text-white text-start mx-auto', styles.sizzlyColor)}
                onClick={() => {
                  localStorage.setItem('orderType', 'inHouse')
                  setOrderTypeModal(false)
                }}
              >
                <FontAwesomeIcon icon={faUtensils} color={'white'} /> <span className="ps-2">INHOUSE</span>
                <span className="float-end">
                  <FontAwesomeIcon icon={faArrowRight} color={'white'} />
                </span>
              </button>
            )}
            {takeAway && (
              <button
                className={classNames('btn w-100 border text-white text-start', styles.sizzlyColor)}
                onClick={() => {
                  localStorage.setItem('orderType', 'takeAway')
                  setOrderTypeModal(false)
                }}
              >
                <FontAwesomeIcon icon={faBagShopping} color={'white'} /> <span className="ps-2">TAKEAWAY</span>
                <span className="float-end">
                  <FontAwesomeIcon icon={faArrowRight} color={'white'} />
                </span>
              </button>
            )}
            {selfService && (
              <button
                className={classNames('btn w-100 border text-white text-start', styles.sizzlyColor)}
                onClick={() => {
                  localStorage.setItem('orderType', 'selfService')
                  setOrderTypeModal(false)
                }}
              >
                {' '}
                <FontAwesomeIcon icon={faPersonWalking} color={'white'} />
                <span className="ps-2">SELFSERVICE</span>
                <span className="float-end">
                  <FontAwesomeIcon icon={faArrowRight} color={'white'} />
                </span>
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default OrderTypeSelection
