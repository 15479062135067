import { faArrowLeft, faCircleXmark, faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'
import { pricePerItem } from '../../services/pricePerItem'
import { Article, Restaurant } from '../../types'
import styles from './RestaurantCategory.module.scss'
import moment from 'moment'

const RestaurantCategory = ({
  data,
  setSelectedArticle,
  t,
  setDarkLogoOnPaths,
}: {
  data: Restaurant
  setSelectedArticle: any
  t: any
  setDarkLogoOnPaths: any
}) => {
  const [categoryImg, setCategoryImg] = useState(null)
  const router = useRouter()
  const { query } = router
  const orderType = localStorage.getItem('orderType')
  let categoryName = null
  const today = moment()

  const { menu_categories, articles, nameUid } = data
  let subCategories: any[] = []
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const findCategory = menu_categories.find(
      (element) => JSON.stringify(element.id) === router.asPath.split('/').pop()
    )
    setCategoryImg(findCategory?.photo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (!categoryImg) {
      setDarkLogoOnPaths(true)
    } else {
      setDarkLogoOnPaths(false)
    }
  }, [data, categoryImg])

  if (articles) {
    articles.sort(function (a: Article, b: Article) {
      let keyA = a.position ? a.position : articles.length,
        keyB = b.position ? b.position : articles.length
      // Compare the 2 positions
      if (keyA < keyB) return -1
      if (keyA > keyB) return 1
      return 0
    })
  }

  if (data) {
    menu_categories?.forEach((category: any) => {
      if (category.parent_category && JSON.stringify(category.parent_category.id) === router.asPath.split('/').pop()) {
        subCategories.push({ category: category, items: [] })
      }
      if (JSON.stringify(category.id) === router.asPath.split('/').pop()) {
        categoryName = category.name
      }
    })

    if (subCategories.length > 0) {
      subCategories.forEach((category) => {
        articles.forEach((article) => {
          if (article.menu_categories?.filter((element) => element.id === category.category.id).length > 0) {
            category.items.push(article)
          }
        })
      })
    } else {
      const findCategory = menu_categories?.find(
        (element) => JSON.stringify(element.id) === router.asPath.split('/').pop()
      )
      subCategories.push({ category: findCategory, items: [] })
      articles.forEach((article) => {
        if (article.menu_categories.filter((element) => element.id === findCategory?.id).length > 0) {
          subCategories[0].items.push(article)
        }
      })
    }
  }

  const returnAricles = (element: Article): JSX.Element | void => {
    const { availabilities, displayImageContain } = element
    const availabilityMatchToday = availabilities.some((x: any) => moment(x.date).isSame(today, 'day'))
    if (!!!availabilities.length || availabilityMatchToday) {
      return (
        <div className={'text-center'} key={element.id}>
          <button
            className={classnames('btn position-relative mb-3', styles.articleElement)}
            onClick={() => {
              let tempElement = { ...element }
              setSelectedArticle(tempElement)
            }}
          >
            <div className={styles.articleImage}>
              {element.photo && (
                <Image
                  src={`${element.photo.url}`}
                  blurDataURL={`${element.photo.url}`}
                  layout="fill"
                  objectFit={!!displayImageContain ? 'contain' : 'cover'}
                  placeholder="blur"
                  alt="Article Image"
                />
              )}
              {!element.photo && (
                <Image
                  src={`${data.restaurantLogo.url}`}
                  alt="Restaurant Logo"
                  layout="fill"
                  objectFit="contain"
                  className="p-2"
                  placeholder="blur"
                  blurDataURL={`${data.restaurantLogo.url}`}
                />
              )}
            </div>
            <div className={classnames(styles.articleHeading, 'h5 m-2')}>{element.name}</div>
            <div className={classnames(styles.articleDescription, ' mx-2 font-weight-lighter text-muted')}>
              {element.description}
            </div>
            <div className={classnames(styles.articlePrice, 'mx-3 h5')}>
              {(pricePerItem(element).price + (orderType === 'takeAway' ? pricePerItem(element).modifierTakeAway : 0))
                .toFixed(2)
                .replace('.', ',')}{' '}
              €
            </div>
          </button>
        </div>
      )
    }
  }
  return (
    <>
      {categoryImg && (
        <Container className={classnames('position-fixed', styles.restaurantHeaderImg)}>
          <Image
            //src={`${data.menu_categories.find((ind) => ind.id === JSON.parse(query.details))}`}
            src={categoryImg}
            blurDataURL={categoryImg}
            layout="fill"
            objectFit="cover"
            placeholder="blur"
            alt="Restaurant Header Image"
          />
        </Container>
      )}
      <Container className={classnames(categoryImg && styles.restaurantWrapper, 'w-100 bg-white')}>
        {categoryImg && <Container className={styles.waveBackground} />}
        {data.emergencyShutdown && (
          <Container className={classnames('text-white p-1 position-fixed d-flex', styles.emergencyContainer)}>
            <span className="align-self-center me-2 ms-1">
              <FontAwesomeIcon icon={faCircleXmark} size={'lg'} />
            </span>
            {t('restaurant:emergencyShutdown')}
          </Container>
        )}
        {data.hintProfile && !data.emergencyShutdown && (
          <Container className={classnames('text-white p-2 position-absolute d-flex', styles.hintContainer)}>
            <span className="align-self-center me-2 ms-1">
              <FontAwesomeIcon icon={faCircleInfo} size={'lg'} />
            </span>
            {data.hintProfile}
          </Container>
        )}
        <Container
          className={classnames(
            'mt-4 my-auto',
            styles.headingContainer,
            categoryImg ? undefined : styles.headingWithoutImg,
            data.emergencyShutdown && styles.emergencyShutdownHeading
          )}
        >
          <button
            className={classnames('rounded-circle btn p-0', styles.backButton)}
            onClick={() => router.push(`/restaurant/${nameUid}/menu`)}
          >
            <FontAwesomeIcon icon={faArrowLeft} size={'lg'} className={styles.arrowBack} />
          </button>
          <span className="text-uppercase m-auto h3 position-absolute align-middle">{categoryName}</span>
        </Container>
        <Container className={classnames(styles.articleContainer, 'mt-4')}>
          {subCategories.length > 1 && (
            <Accordion defaultActiveKey="0" className="accordion text-danger bg-primary" flush>
              {subCategories.map((subs, index) => {
                return (
                  <Accordion.Item eventKey={`${index}`} className="accordion-item" key={index}>
                    <Accordion.Button>{subs.category.name}</Accordion.Button>
                    <Accordion.Body>
                      {subs.items.map((element: any) => {
                        if (element.availableItems > 0) {
                          return returnAricles(element)
                        }
                      })}
                    </Accordion.Body>
                  </Accordion.Item>
                )
              })}
            </Accordion>
          )}
          <div className={styles.articleWrapper}>
            {subCategories.length === 1 &&
              subCategories[0].items.map((element: any) => {
                if (element.availableItems > 0) {
                  return returnAricles(element)
                }
              })}
          </div>
        </Container>
      </Container>
    </>
  )
}

export default RestaurantCategory
