import { Restaurant } from '../../types'
import ReactMarkdown from 'react-markdown'
import styles from './RestaurantInfo.module.scss'
import { useState, createRef, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import classnames from 'classnames'
import RestaurantInfoCarousel from '../RestaurantInfoCarousel/RestaurantInfoCarousel'

const RestaurantInfo = ({ data, openingHours }: { data: Restaurant; openingHours: any }) => {
  const [toggleDescription, setToggleDescription] = useState(false)
  const [showExpandButton, setShowExpandButton] = useState(false)
  const restaurantDescriptionRef = createRef<HTMLDivElement>()

  useLayoutEffect(() => {
    if (
      restaurantDescriptionRef.current &&
      restaurantDescriptionRef.current.clientHeight < restaurantDescriptionRef.current.scrollHeight
    ) {
      setShowExpandButton(true)
    }
  }, [restaurantDescriptionRef])

  const { t } = useTranslation(['restaurant'])
  return (
    <>
      <div className={toggleDescription ? undefined : styles.description} ref={restaurantDescriptionRef}>
        <ReactMarkdown className="py-3 mx-4">{data.restaurantDescription}</ReactMarkdown>
      </div>
      <div className={'text-center mb-5'}>
        {showExpandButton && (
          <button
            onClick={() => setToggleDescription(!toggleDescription)}
            className={classnames('btn text-center mt-3 rounded-pill text-uppercase', styles.expandButton)}
          >
            {toggleDescription ? (
              <div>
                <FontAwesomeIcon icon={faAngleUp} /> {t('restaurant:collapse')}
              </div>
            ) : (
              <div>
                <FontAwesomeIcon icon={faAngleDown} /> {t('restaurant:expand')}
              </div>
            )}
          </button>
        )}
      </div>
      <RestaurantInfoCarousel data={data} openingHours={openingHours} t={t} />
    </>
  )
}

export default RestaurantInfo
