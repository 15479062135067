import {
  faArrowLeft,
  faBagShopping,
  faClock,
  faPersonWalking,
  faUtensils,
  faCircleXmark,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState, useRef } from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ArticleModal from '../../../components/ArticleModal/ArticleModal'
import LoadingAnimation from '../../../components/LoadingAnimation/LoadingAnimation'
import OrderTypeSelection from '../../../components/OrderTypeSelection/OrderTypeSelection'
import RestaurantCategory from '../../../components/RestaurantCategory/RestaurantCategory'
import RestaurantInfo from '../../../components/RestaurantInfo/RestaurantInfo'
import RestaurantMenu from '../../../components/RestaurantMenu/RestaurantMenu'
import { makeRequest } from '../../../services/axios'
import { OpeningHours } from '../../../services/openingHours'
import { Article, Restaurant } from '../../../types'
import Custom500 from '../../500'
import styles from '../restaurant.module.scss'

const RestaurantPage = ({
  showShoppingBag,
  setShowShoppingBag,
  shoppingBag,
  setShoppingBag,
  loading,
  setLoading,
  setDarkLogoOnPaths,
}: {
  showShoppingBag: any
  setShowShoppingBag: any
  shoppingBag: any
  setShoppingBag: any
  loading: any
  setLoading: any
  setDarkLogoOnPaths: any
}) => {
  const router = useRouter()
  const { query } = router
  const { t } = useTranslation(['restaurant'])

  const [data, setData] = useState<null | Restaurant>(null)
  const [categoryId, setCategoryId] = useState(router.asPath.split('/').pop())
  const [selectedArticle, setSelectedArticle] = useState<Article | null>(null)
  const [toggle, setToggle] = useState(router.asPath.split('/').pop())
  const [orderTypeModal, setOrderTypeModal] = useState(false)
  const [error, setError] = useState<null | string>(null)

  useEffect(() => {
    const getRestaurantData = async () => {
      const restaurantData: Restaurant = await makeRequest(
        'get',
        `/restaurants/nameUid/${query.id}?optionsIngredientsLookup=true`
      )
      if (restaurantData.nameUid) {
        setData(restaurantData)
      } else {
        setError(restaurantData.code)
      }
    }
    if (query.id && !data && !error) getRestaurantData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    if (!localStorage.getItem('cart')) {
      setShoppingBag(null)
    } else {
      setShoppingBag(JSON.parse(localStorage.getItem('cart') as string))
    }
  }, [typeof window !== 'undefined' ? localStorage.getItem('cart') : null])

  useEffect(() => {
    const path = router.asPath.split('/').pop()
    setToggle(path)
    if (path !== ('profile' || 'menu')) {
      setCategoryId(path)
    }
  }, [router.asPath])

  useEffect(() => {
    const orderType = localStorage.getItem('orderType')
    if (!orderType) {
      setOrderTypeModal(true)
    }
  }, [])

  if (loading) return <LoadingAnimation loading={null} />

  if (data) {
    const {
      id,
      nameUid,
      restaurantName,
      restaurantTagOne,
      restaurantAddress,
      shifts,
      restaurantLogo,
      restaurantPhotos,
      restaurantTakeAway,
      restaurantInHouse,
      selfService,
      restaurantScheduledClosings,
      disableComments,
      emergencyShutdown,
      hintProfile,
    } = data

    const showOrderTypeSelection = () => {
      return (
        <OrderTypeSelection
          orderTypeModal={orderTypeModal}
          setOrderTypeModal={setOrderTypeModal}
          restaurantName={restaurantName}
          takeAway={restaurantTakeAway}
          inHouse={restaurantInHouse}
          selfService={selfService}
          t={t}
        />
      )
    }

    const showSelectedArticle = () => {
      if (selectedArticle)
        return (
          <ArticleModal
            selectedArticle={selectedArticle}
            setSelectedArticle={setSelectedArticle}
            nameUid={nameUid}
            id={id}
            disableComments={disableComments}
            disablePayment={data.disablePayment}
          />
        )
    }

    if (toggle === 'menu' || toggle === 'profile') {
      const open = OpeningHours(shifts, restaurantScheduledClosings)
      return (
        <>
          {showOrderTypeSelection()}
          <Container className={classnames('position-fixed', styles.restaurantHeaderImg)}>
            {restaurantPhotos && restaurantPhotos[0]?.url && (
              <Image
                src={`${restaurantPhotos[0]?.url}`}
                alt="Restaurant Image"
                placeholder="blur"
                blurDataURL={`${restaurantPhotos[0]?.url}`}
                objectFit="cover"
                layout="fill"
                priority={true}
              />
            )}
          </Container>
          <Container className={classnames(styles.restaurantWrapper, 'w-100')}>
            <Container className={classnames('text-center', styles.waveBackground)}>
              <div className={classnames(styles.restaurantCardLogo, 'mx-auto')}>
                {restaurantLogo?.url && (
                  <Image
                    src={`${restaurantLogo?.url}`}
                    alt="Restaurant Image"
                    placeholder="blur"
                    blurDataURL={`${restaurantLogo?.url}`}
                    objectFit="contain"
                    layout="fill"
                    className="p-2"
                    priority={true}
                  />
                )}
              </div>
            </Container>
            {emergencyShutdown && (
              <Container className={classnames('text-white p-1 position-fixed d-flex', styles.emergencyContainer)}>
                <span className="align-self-center me-2 ms-1">
                  <FontAwesomeIcon icon={faCircleXmark} size={'lg'} />
                </span>
                {t('restaurant:emergencyShutdown')}
              </Container>
            )}
            {hintProfile && !emergencyShutdown && (
              <Container className={classnames('text-white p-1 position-absolute d-flex', styles.hintContainer)}>
                <span className="align-self-center me-2 ms-1">
                  <FontAwesomeIcon icon={faCircleInfo} size={'lg'} />
                </span>
                {hintProfile}
              </Container>
            )}
            <div className="bg-white mw-100">
              <Container className={classnames(styles.restaurantHeader, 'text-center')}>
                <div className={classnames(styles.divMargin, 'd-table mb-2 w-100')}>
                  <div className="d-table-row">
                    <div className="d-table-cell w-25 ps-4">
                      <Link href="/restaurants" replace className="d-table">
                        <a
                          className={classnames(
                            styles.restaurantBackButton,
                            'btn ms-4 d-table-cell rounded-circle p-0'
                          )}
                        >
                          <FontAwesomeIcon icon={faArrowLeft} size={'lg'} className={styles.backArrow} />
                        </a>
                      </Link>
                    </div>
                    <h3 className="d-table-cell w-50 align-middle">{restaurantName}</h3>
                    <div className="d-table-cell w-25" />
                  </div>
                </div>
                <h6 className="pb-2">{restaurantTagOne}</h6>
                <div className="text-left d-flex justify-content-center mb-2">
                  {restaurantInHouse && (
                    <h6 className="mx-1">
                      <FontAwesomeIcon icon={faUtensils} color={'#462fee'} /> {t('restaurant:dineIn')}
                    </h6>
                  )}
                  {restaurantTakeAway && (
                    <h6 className="mx-1">
                      <FontAwesomeIcon icon={faBagShopping} color={'#462fee'} /> {t('restaurant:takeAway')}
                    </h6>
                  )}
                  {selfService && (
                    <h6 className="mx-1">
                      <FontAwesomeIcon icon={faPersonWalking} color={'#462fee'} /> {t('restaurant:selfService')}
                    </h6>
                  )}
                </div>
                <div className="pb-2">
                  <h6>
                    <FontAwesomeIcon
                      icon={faClock}
                      color={open.isOpen ? '#28a745' : open.opensToday ? '#ffc107' : '#dc3545'}
                    />{' '}
                    {open.isOpen && t('restaurant:openingRestaurant') + ' ' + t('restaurant:opened')}
                    {!open.isOpen && open.opensToday && t('restaurant:currentlyClosed')}
                    {!open.isOpen && !open.opensToday && t('restaurant:closedToday')}
                  </h6>
                </div>
                <div className={classnames('btn-group', styles.restaurantToggle)} role="group">
                  <button
                    type="button"
                    className={classnames(
                      'btn w-50',
                      toggle === 'profile' ? styles.leftButtonSelected : styles.leftButton
                    )}
                    onClick={() => router.push(`/restaurant/${router.query.id}/profile`, undefined, { shallow: true })}
                  >
                    {t('restaurant:profile')}
                  </button>
                  <button
                    type="button"
                    className={classnames(
                      'btn w-50',
                      toggle === 'menu' ? styles.rightButtonSelected : styles.rightButton
                    )}
                    onClick={() => router.push(`/restaurant/${router.query.id}/menu`, undefined, { shallow: true })}
                  >
                    {t('restaurant:menu')}
                  </button>
                </div>
              </Container>
            </div>
            {toggle === 'profile' && (
              <Container className="mw-100 bg-white">
                <Container className={classnames('pb-2', styles.restaurantInfo)}>
                  <RestaurantInfo data={data} openingHours={open} />
                </Container>
              </Container>
            )}
            {toggle === 'menu' && (
              <Container className="mw-100 bg-white">
                <Container
                  className={classnames('p-3 d-flex justify-content-center text-center', styles.restaurantInfo)}
                >
                  <RestaurantMenu data={data} setSelectedArticle={setSelectedArticle} />
                  {showSelectedArticle()}
                </Container>
              </Container>
            )}
          </Container>
        </>
      )
    } else if (data.menu_categories) {
      return (
        <>
          {showOrderTypeSelection()}
          <RestaurantCategory
            data={data}
            setSelectedArticle={setSelectedArticle}
            t={t}
            setDarkLogoOnPaths={setDarkLogoOnPaths}
          />
          {showSelectedArticle()}
        </>
      )
    } else {
      return <Custom500 />
    }
  }
  if (error) {
    return <Custom500 />
  } else {
    return <LoadingAnimation loading={null} />
  }
}

export default RestaurantPage
