import { Container } from 'react-bootstrap'
import Image from 'next/image'
import Carousel, { ArrowProps } from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import styles from './RestaurantInfoCarousel.module.scss'
import { ResponsiveCarouselConfig } from './RestaurantInfoCarousel.config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLocationDot,
  faContactCard,
  faClock,
  faPhone,
  faEnvelope,
  faArrowUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons'
import classnames from 'classnames'
import { Restaurant } from '../../types'
import moment from 'moment'

const restaurantInfoCarousel = ({
  data,
  openingHours,
  t,
}: {
  data: Restaurant
  openingHours: any
  t: any
}): JSX.Element => {
  const { restaurantAddress } = data
  return (
    <Container className={styles.restaurantInfoCarouselContainer}>
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlay={false}
        swipeable
        draggable
        focusOnSelect={false}
        showDots={false}
        responsive={ResponsiveCarouselConfig}
        infinite={false}
        renderButtonGroupOutside
        shouldResetAutoplay={false}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
      >
        {data.nameUid !== 'bayernbankett' && (
          <Container className={styles.restaurantInfoCarouselItem}>
            <div
              className={styles.restaurantInfoCarouselItemImage}
              style={{
                backgroundImage: 'url(/restaurant-contact-images/Öffnungszeiten.webp)',
              }}
            >
              <div className={styles.restaurantInfoLogo}>
                <FontAwesomeIcon icon={faClock} color={'#462fee'} size={'2x'} />
              </div>
              <div className={classnames('mt-3', styles.fontBold)}>{t('restaurant:openingHours')}</div>
              <div className="mt-2">
                <h6>
                  <FontAwesomeIcon
                    icon={faClock}
                    color={openingHours.isOpen ? '#28a745' : openingHours.opensToday ? '#ffc107' : '#dc3545'}
                  />{' '}
                  {openingHours.isOpen ? t('restaurant:currentlyOpen') : t('restaurant:currentlyClosed')}
                </h6>
              </div>
              {data.restaurantScheduledClosings.length > 0 &&
                data.restaurantScheduledClosings.some(
                  (x) =>
                    moment(x.restaurantScheduledClosingStart).isSameOrAfter(moment()) ||
                    moment().isBetween(x.restaurantScheduledClosingStart, x.restaurantScheduledClosingEnd)
                ) && (
                  <div className="my-3">
                    <strong>{t('restaurant:scheduledClosings')}</strong>
                    {data.restaurantScheduledClosings.map((time, index) => {
                      const startTime = new Date(time.restaurantScheduledClosingStart)
                      const endTime = new Date(time.restaurantScheduledClosingEnd)
                      const today = moment()
                      if (
                        moment(time.restaurantScheduledClosingStart).isSameOrAfter(moment()) ||
                        today.isBetween(time.restaurantScheduledClosingStart, time.restaurantScheduledClosingEnd)
                      ) {
                        return (
                          <div className="mt-1" key={`${index}`}>
                            {startTime.getDate() +
                              '.' +
                              (startTime.getMonth() + 1) +
                              '.' +
                              startTime.getFullYear() +
                              ' - ' +
                              endTime.getUTCDate() +
                              '.' +
                              (endTime.getMonth() + 1) +
                              '.' +
                              endTime.getFullYear()}
                          </div>
                        )
                      }
                    })}
                  </div>
                )}
              <div className="p-2">
                <table className="w-100 float-start text-start">
                  <tbody>
                    <td className=" w-50">{t('restaurant:monday')}: </td>
                    <td className=" w-50">
                      {openingHours.week[0].length > 0 &&
                        openingHours.week[0].map((val: string, index: number) => {
                          return <div key={index}>{val} </div>
                        })}
                      {openingHours.week[0].length === 0 && <div>{t('restaurant:closed')}</div>}
                    </td>
                  </tbody>
                  <tbody>
                    <td className=" w-50">{t('restaurant:tuesday')}: </td>
                    <td className=" w-50">
                      {openingHours.week[1].length > 0 &&
                        openingHours.week[1].map((val: string, index: number) => {
                          return <div key={index}>{val} </div>
                        })}
                      {openingHours.week[1].length === 0 && <div>{t('restaurant:closed')}</div>}
                    </td>
                  </tbody>
                  <tbody>
                    <td className=" w-50">{t('restaurant:wednesday')}: </td>
                    <td className=" w-50">
                      {openingHours.week[2].length > 0 &&
                        openingHours.week[2].map((val: string, index: number) => {
                          return <div key={index}>{val} </div>
                        })}
                      {openingHours.week[2].length === 0 && <div>{t('restaurant:closed')}</div>}
                    </td>
                  </tbody>
                  <tbody>
                    <td className=" w-50">{t('restaurant:thursday')}: </td>
                    <td className=" w-50">
                      {openingHours.week[3].length > 0 &&
                        openingHours.week[3].map((val: string, index: number) => {
                          return <div key={index}>{val} </div>
                        })}
                      {openingHours.week[3].length === 0 && <div>{t('restaurant:closed')}</div>}
                    </td>
                  </tbody>
                  <tbody>
                    <td className=" w-50">{t('restaurant:friday')}: </td>
                    <td className=" w-50">
                      {openingHours.week[4].length > 0 &&
                        openingHours.week[4].map((val: string, index: number) => {
                          return <div key={index}>{val} </div>
                        })}
                      {openingHours.week[4].length === 0 && <div>{t('restaurant:closed')}</div>}
                    </td>
                  </tbody>
                  <tbody>
                    <td className=" w-50">{t('restaurant:saturday')}: </td>
                    <td className=" w-50">
                      {openingHours.week[5].length > 0 &&
                        openingHours.week[5].map((val: string, index: number) => {
                          return <div key={index}>{val} </div>
                        })}
                      {openingHours.week[5].length === 0 && <div>{t('restaurant:closed')}</div>}
                    </td>
                  </tbody>
                  <tbody>
                    <td className=" w-50">{t('restaurant:sunday')}: </td>
                    <td className=" w-50">
                      {openingHours.week[6].length > 0 &&
                        openingHours.week[6].map((val: string, index: number) => {
                          return <div key={index}>{val} </div>
                        })}
                      {openingHours.week[6].length === 0 && <div>{t('restaurant:closed')}</div>}
                    </td>
                  </tbody>
                </table>
              </div>
            </div>
          </Container>
        )}
        <Container className={styles.restaurantInfoCarouselItem}>
          <div
            className={styles.restaurantInfoCarouselItemImage}
            style={{
              backgroundImage: 'url(/restaurant-contact-images/Adresse.webp)',
            }}
          >
            <div className={styles.restaurantInfoLogo}>
              <FontAwesomeIcon icon={faLocationDot} color={'#462fee'} size={'2x'} />
            </div>
            <div className={classnames('mt-3', styles.fontBold)}>{t('restaurant:address')}</div>
            <div className="mt-2">
              {restaurantAddress.restaurantStreet} {restaurantAddress.restaurantHouseNumber}
            </div>
            <div>
              {restaurantAddress.restaurantPostalCode} {restaurantAddress.restaurantCity}
            </div>
          </div>
        </Container>
        <Container className={styles.restaurantInfoCarouselItem}>
          <div
            className={styles.restaurantInfoCarouselItemImage}
            style={{
              backgroundImage: 'url(/restaurant-contact-images/Kokntaktdaten.webp)',
            }}
          >
            <div className={styles.restaurantInfoLogo}>
              <FontAwesomeIcon icon={faContactCard} color={'#462fee'} size={'2x'} />
            </div>
            <div className={classnames('mt-3', styles.fontBold)}>{t('restaurant:contact')}</div>
            <div className="mt-1">
              <FontAwesomeIcon icon={faPhone} color={'black'} size={'sm'} />
            </div>
            <a href={`tel:+${restaurantAddress.restaurantPhone}`}>+{restaurantAddress.restaurantPhone}</a>
            <div className="mt-1">
              <FontAwesomeIcon icon={faEnvelope} color={'black'} size={'sm'} />
            </div>
            <a href={`mailto:${restaurantAddress.restaurantEmail}`}>{restaurantAddress.restaurantEmail}</a>
            <div className="mt-1">
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} color={'black'} size={'sm'} />
            </div>
            <a href={'https://' + restaurantAddress.restaurantWebsite} rel="noreferrer noopener" target="_blank">
              {restaurantAddress.restaurantWebsite}
            </a>
          </div>
        </Container>
      </Carousel>
    </Container>
  )
}
const CustomLeftArrow = ({ onClick }: ArrowProps): JSX.Element => (
  <Container onClick={onClick}>
    <i className={styles.customLeftArrow}></i>
  </Container>
)
const CustomRightArrow = ({ onClick }: ArrowProps): JSX.Element => (
  <Container onClick={onClick}>
    <i className={styles.customRightArrow}></i>
  </Container>
)

export default restaurantInfoCarousel
