import styles from './RestaurantMenu.module.scss'
import articleStyles from '../RestaurantCategory/RestaurantCategory.module.scss'
import { Restaurant, MenuCategory } from '../../types'
import classNames from 'classnames'
import { Pagination } from 'react-bootstrap'
import moment from 'moment'

import Image from 'next/image'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import classnames from 'classnames'
import { pricePerItem } from '../../services/pricePerItem'
import { useTranslation } from 'react-i18next'

const RestaurantMenu = ({ data, setSelectedArticle }: { data: Restaurant; setSelectedArticle: any }) => {
  const router = useRouter()
  const { query } = router
  const { i18n } = useTranslation()

  const [selectedDay, setSelectedDay] = useState(moment())
  const [week, setWeek] = useState('current')
  const [startOfWeek, setStartOfWeek] = useState(moment().startOf('week').add(1, 'day'))
  const [currentWeekArticles, setCurrentWeekArticles] = useState(filterArticles(selectedDay))
  data.menu_categories?.sort(function (a: MenuCategory, b: MenuCategory) {
    let keyA = a.position ? a.position : 0,
      keyB = b.position ? b.position : 0
    // Compare the 2 positions
    if (keyA < keyB) return -1
    if (keyA > keyB) return 1
    return 0
  })

  const weekDays = Array.from(Array(5).keys())

  function filterArticles(day: any) {
    return data.articles?.filter((x) => x.availabilities.some((y: any) => moment(y.date).isSame(day, 'day')))
  }

  useEffect(() => {
    setCurrentWeekArticles(filterArticles(selectedDay))
  }, [selectedDay])

  useEffect(() => {
    if (week === 'current') {
      setSelectedDay(moment())
      setStartOfWeek(moment().startOf('week').add(1, 'day'))
    }
    if (week === 'next') {
      const tempStartOfWeek = moment().add(1, 'week').startOf('week').add(1, 'day')
      setStartOfWeek(tempStartOfWeek)
      setSelectedDay(tempStartOfWeek)
    }
  }, [week])

  return (
    <div className={'d-flex justify-content-center flex-wrap w-100'}>
      {!data.weekView &&
        data.menu_categories?.map((category) => {
          if (!category.parent_category) {
            return (
              <button
                className={classNames(
                  'btn col-md-offset-2 vertical-center',
                  category.photo?.url ? styles.categoryContainerPhoto : styles.categoryContainer
                )}
                style={
                  category && category.photo
                    ? {
                        backgroundImage: `linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.7)), url(${category.photo})`,
                        backgroundSize: 'cover',
                      }
                    : undefined
                }
                onClick={() => {
                  router.push(`/restaurant/${router.query.id}/${category.id}`, undefined, { shallow: true })
                  window.scrollTo(0, 0)
                }}
                key={category.id}
              >
                <div className={classNames('text-center w-100', styles.hyphenization)}>{category.name}</div>
              </button>
            )
          }
        })}
      {data.weekView && (
        <div className="w-100">
          <Pagination aria-label="Page navigation" size="lg">
            <Pagination.Prev disabled={week === 'current'} onClick={() => setWeek('current')} />
            {weekDays.map((item) => {
              const itemDate = moment(startOfWeek).add(item, 'day')
              return (
                <Pagination.Item
                  active={itemDate.isSame(selectedDay, 'day')}
                  key={`${item}-${itemDate.format('YYYY-MM-DD')}`}
                  onClick={() => {
                    setSelectedDay(itemDate)
                  }}
                >
                  <div>
                    {new Date(itemDate as any).toLocaleDateString(i18n.language, {
                      weekday: 'short',
                    })}
                  </div>
                  <div>{itemDate.format('DD')}</div>
                </Pagination.Item>
              )
            })}
            <Pagination.Next onClick={() => setWeek('next')} disabled={week === 'next'} />
          </Pagination>
          {currentWeekArticles.map((x) => {
            return (
              <div className={'text-center w-100'} key={x.id}>
                <button
                  className={classnames('btn position-relative mb-3 w-100', articleStyles.articleElement)}
                  onClick={() => {
                    let tempElement = { ...x }
                    setSelectedArticle(tempElement)
                  }}
                >
                  <div className={articleStyles.articleImage}>
                    {x.photo && (
                      <Image
                        src={`${x.photo.url}`}
                        blurDataURL={`${x.photo.url}`}
                        layout="fill"
                        objectFit="cover"
                        placeholder="blur"
                        alt="Article Image"
                      />
                    )}
                    {!x.photo && (
                      <Image
                        src={`${data.restaurantLogo.url}`}
                        alt="Restaurant Logo"
                        layout="fill"
                        objectFit="contain"
                        className="p-2"
                        placeholder="blur"
                        blurDataURL={`${data.restaurantLogo.url}`}
                      />
                    )}
                  </div>
                  <div className={classnames(articleStyles.articleHeading, 'h5 m-2')}>{x.name}</div>
                  <div className={classnames(articleStyles.articleDescription, ' mx-2 font-weight-lighter text-muted')}>
                    {x.description}
                  </div>
                  <div className={classnames(articleStyles.articlePrice, 'mx-3 h5')}>
                    {pricePerItem(x).price.toFixed(2).replace('.', ',')} €
                  </div>
                </button>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default RestaurantMenu
