import {
  faAngleDown,
  faAngleUp,
  faBasketShopping,
  faCircleInfo,
  faCircleMinus,
  faCirclePlus,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import Image from 'next/image'
import { useEffect, useState, useRef, useLayoutEffect, createRef } from 'react'
import { Modal, OverlayTrigger, Popover, FloatingLabel, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { calcLocalItemsAmount } from '../../services/calcLocalItemsAmount'
import { Article, Module, ModuleOption, ModuleOuterInner, SelectedOption } from '../../types'
import styles from './ArticleModal.module.scss'
import { number } from 'prop-types'

const ArticleModal = ({
  selectedArticle,
  setSelectedArticle,
  nameUid,
  id,
  disableComments,
  disablePayment,
}: {
  selectedArticle: Article
  setSelectedArticle: any
  nameUid: string
  id: number
  disableComments: boolean
  disablePayment: boolean | null
}) => {
  const { t } = useTranslation(['restaurant'])
  const orderType = localStorage.getItem('orderType')

  const {
    name,
    description,
    price,
    availableItems,
    availableForTakeAway,
    priceModifierTakeAway,
    amountPriceModifierTakeAway,
    modifierTypeTakeAway,
    type,
    photo,
    trackItemAmount,
    displayImageContain,
  } = selectedArticle

  let { optionModules } = selectedArticle

  const descriptionRef = createRef<HTMLDivElement>()
  const [expandButton, setExpandButton] = useState(false)
  const [expandDescription, setExpandDescription] = useState(false)
  const [itemQuantity, setItemQuantity] = useState(1)
  const [itemPrice, setItemPrice] = useState(price)
  const [takeAwayModifier, setTakeAwayModifier] = useState(
    modifierTypeTakeAway && priceModifierTakeAway && amountPriceModifierTakeAway
      ? modifierTypeTakeAway === 'surplus'
        ? amountPriceModifierTakeAway
        : -amountPriceModifierTakeAway
      : 0
  )
  const [comment, setComment] = useState('')
  const [restaurantMisMatch, setRestaurantMisMatch] = useState(false)

  useEffect(() => {
    let index = 0
    optionModules.map((optModule) => {
      const { module }: { module: Module } = optModule
      const { module_outer_inner }: { module_outer_inner: ModuleOuterInner } = optModule
      const { options } = module ? module : module_outer_inner

      if (module) {
        module.quantity = 0
      } else {
        module_outer_inner.quantity = 0
      }

      options?.map((opt) => {
        opt.uid = index
        index = index + 1
        if (opt.isDefault) {
          opt.selected = true
          opt.option.quantity = 1
          optModule.quantity = optModule?.quantity ? optModule.quantity + 1 : 1
        } else {
          opt.selected = false
          opt.option.quantity = 0
        }
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let tempItemPrice = price
    let tempTakeAwayModifier: number =
      modifierTypeTakeAway && priceModifierTakeAway && amountPriceModifierTakeAway
        ? modifierTypeTakeAway === 'surplus'
          ? amountPriceModifierTakeAway
          : -amountPriceModifierTakeAway
        : 0
    optionModules.forEach((modules) => {
      const { module }: { module: Module } = modules
      const { module_outer_inner }: { module_outer_inner: any } = modules
      const { options } = module ? module : module_outer_inner
      options.forEach((opt: ModuleOption) => {
        if (opt.selected) {
          if (opt.modifierTypeTakeAway && opt.priceModifierTakeAway) {
            if (opt.modifierTypeTakeAway === 'surplus' && opt.option.quantity) {
              tempTakeAwayModifier = tempTakeAwayModifier + opt.option.quantity * opt.priceModifierTakeAway
            }
            if (opt.modifierTypeTakeAway === 'reduction' && opt.option.quantity) {
              tempTakeAwayModifier = tempTakeAwayModifier - opt.option.quantity * opt.priceModifierTakeAway
            }
          }
          if (opt.modifierType === 'surplus' && opt.option.quantity) {
            tempItemPrice = tempItemPrice + opt.option.quantity * opt.priceModifier
          }
          if (opt.modifierType === 'reduction' && opt.option.quantity) {
            tempItemPrice = tempItemPrice - opt.option.quantity * opt.priceModifier
          }
        }
      })
    })
    setTakeAwayModifier(tempTakeAwayModifier)
    setItemPrice(tempItemPrice)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArticle])

  useLayoutEffect(() => {
    if (descriptionRef.current && descriptionRef.current.clientHeight < descriptionRef.current.scrollHeight) {
      setExpandButton(true)
    }
  }, [descriptionRef])

  const moduleUpdate = (mod: Module, option: ModuleOption, index: number, operation: string) => {
    if (operation === 'select') {
      if (mod.selectionRequired && mod.options) {
        mod.options.forEach((opt) => {
          if (opt.uid === option.uid) {
            opt.selected = true
            opt.option.quantity = 1
          } else {
            opt.selected = false
            opt.option.quantity = 0
          }
        })
      } else {
        mod.options.forEach((opt) => {
          if (opt.uid === option.uid) {
            if (opt.selected) {
              mod.quantity = (mod.quantity ? mod.quantity : 0) - (opt.option?.quantity ? opt.option.quantity : 0)
              opt.option.quantity = 0
            } else {
              mod.quantity = mod.quantity ? mod.quantity + 1 : 1
              opt.option.quantity = 1
            }
            opt.selected = !opt.selected
          }
        })
      }
    }
    if (operation === 'decrease') {
      mod.options.forEach((opt) => {
        if (opt.uid === option.uid) {
          opt.option.quantity = opt.option.quantity ? opt.option.quantity - 1 : 1
          mod.quantity = mod.quantity ? mod.quantity - 1 : 1
        }
      })
    }
    if (operation === 'increase') {
      mod.options.forEach((opt) => {
        if (opt.uid === option.uid) {
          opt.option.quantity = opt.option.quantity ? opt.option.quantity + 1 : 1
          mod.quantity = mod.quantity ? mod.quantity + 1 : 1
        }
      })
    }
    setSelectedArticle((prevState: any) => ({
      ...prevState,
      [prevState.optionModules[index]]: mod,
    }))
  }

  const addToCart = () => {
    let localItems = JSON.parse(localStorage.getItem('cart') as string)
    let localPrice = JSON.parse(localStorage.getItem('localPrice') as string)
    let localTakeAwayModifier = JSON.parse(localStorage.getItem('localTakeAwayModifier') as string)
    const localUid: unknown = localStorage.getItem('localRestaurantUid') as unknown
    if (nameUid === localUid || !localUid || restaurantMisMatch) {
      if (restaurantMisMatch) {
        localStorage.removeItem('cart')
        localStorage.removeItem('localPrice')
        localStorage.removeItem('localTakeAwayModifier')
        localStorage.removeItem('localRestaurantUid')
        localPrice = 0
        localTakeAwayModifier = 0
        localItems = null
      }
      selectedArticle.quantity = itemQuantity
      selectedArticle.comment = comment
      let orderModule: SelectedOption[] = []
      optionModules.forEach((mod) => {
        const { module, id }: { module: Module; id: number } = mod
        const { module_outer_inner }: { module_outer_inner: any } = mod
        const { options, name } = module ? module : module_outer_inner
        options.forEach((opt: ModuleOption) => {
          if (opt.selected) {
            let selectedOption: SelectedOption = {
              optionId: JSON.stringify(opt.option.id),
              optionName: opt.option.name,
              moduleName: name,
              moduleId: JSON.stringify(id),
              priceModifier: opt.priceModifier,
              modifierType: opt.modifierType,
              quantity: opt.option.quantity ? opt.option.quantity : 0,
              priceModifierTakeAway: opt.priceModifierTakeAway,
              modifierTypeTakeAway: opt.modifierTypeTakeAway,
              comment: comment,
            }
            orderModule.push(selectedOption)
          }
        })
      })
      selectedArticle.orderModule = orderModule
      if (localItems) {
        localItems.push(selectedArticle)
      } else {
        localItems = [selectedArticle]
      }
      localStorage.setItem('localRestaurantUid', nameUid)
      // @ts-expect-error
      localStorage.setItem('localRestaurantId', id)
      localStorage.setItem('cart', JSON.stringify(localItems))
      localStorage.setItem('localPrice', JSON.stringify(itemQuantity * itemPrice + localPrice))
      localStorage.setItem(
        'localTakeAwayModifier',
        JSON.stringify(itemQuantity * takeAwayModifier + localTakeAwayModifier)
      )
      setSelectedArticle(null)
      setRestaurantMisMatch(false)
    }
    if (!restaurantMisMatch) {
      setRestaurantMisMatch(true)
    }
  }

  return (
    <>
      <Modal
        show={selectedArticle !== null}
        onHide={() => {
          setSelectedArticle(null)
          setRestaurantMisMatch(false)
        }}
        scrollable={true}
        centered={true}
      >
        <Modal.Header closeButton className={classnames('modal-header border-0 bg-grays-900', styles.header)}>
          <Modal.Title className={classnames('modal-title border-0', styles.modalTitle)}>
            <div className={classnames('modal-content bg-transparent border-0 w-100', styles.articleName)}>{name}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={classnames('modal-body', styles.backgroundColor, styles.modalBody)}>
          {(photo?.name || photo?.url) && (
            <div className={classnames(styles.backgroundColor, styles.articleImage, 'mb-3 position-relative')}>
              <Image
                src={`${photo?.name ?? photo?.url}`}
                layout="fill"
                alt="Article Photo"
                objectFit={!!displayImageContain ? 'contain' : 'cover'}
                className={styles.borderRadiusImg}
              />
            </div>
          )}
          <div
            className={classnames('mx-3', expandDescription ? styles.articleDescription : styles.truncatedDescription)}
            ref={descriptionRef}
          >
            {selectedArticle.description}
          </div>
          {expandButton && (
            <button
              className={classnames('btn', styles.sizzlyColorHover)}
              onClick={() => setExpandDescription(!expandDescription)}
            >
              {expandDescription ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}{' '}
              {expandDescription ? t('restaurant:collapse') : t('restaurant:expand')}
            </button>
          )}
          {((selectedArticle.allergens && selectedArticle.allergens?.length > 0) ||
            (selectedArticle.ingredients && selectedArticle.ingredients?.length > 0)) && (
            <div className="mx-3 mt-2">
              {t('restaurant:ingredientAndAllergens')}
              <OverlayTrigger
                trigger="click"
                key="bottom"
                placement="bottom"
                overlay={
                  <Popover id={`popover-positioned-bottom`}>
                    <Popover.Body>
                      {selectedArticle.allergens?.map((allergen, index) => {
                        return <div key={index}>{allergen.name}</div>
                      })}
                      {selectedArticle.ingredients?.map((ingredient, index) => {
                        return <div key={index}>{ingredient.name}</div>
                      })}
                    </Popover.Body>
                  </Popover>
                }
              >
                <button className={classnames('btn', styles.sizzlyColorHover)}>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </button>
              </OverlayTrigger>
            </div>
          )}
          <div className="mt-4">
            {optionModules &&
              optionModules.map((modules, index) => {
                let { module }: { module: Module } = modules
                let { module_outer_inner }: { module_outer_inner: any } = modules
                let { name, id, maxAmount, selectionRequired, options } = module ? module : module_outer_inner
                return (
                  <div className={classnames('p-3 border mb-3', styles.optionsContainer)} key={`Module: ${id}`}>
                    <div className={styles.fontWeightBold}>{name}</div>
                    {maxAmount && !selectionRequired && (
                      <div className={classnames(styles.sizzlyColor, 'mt-2')}>
                        <FontAwesomeIcon icon={faCircleInfo} /> {t('restaurant:upTo')} {maxAmount}{' '}
                        {maxAmount > 1 ? t('restaurant:options') : t('restaurant:option')} (
                        {module ? module.quantity ?? 0 : module_outer_inner.quantity ?? 0}/{maxAmount})
                      </div>
                    )}
                    <div>
                      {options.map((opt: ModuleOption) => {
                        if (opt.option?.availableItems > 0) {
                          return (
                            <div key={`Option: ${opt.option?.id}`}>
                              <label className="d-flex align-items-center">
                                <input
                                  type={selectionRequired ? 'radio' : 'checkbox'}
                                  id={`Module${opt.option.id}`}
                                  defaultChecked={opt.isDefault ? opt.isDefault : false}
                                  name={`${id}`}
                                  className="p-2"
                                  disabled={
                                    // @ts-expect-error
                                    (!selectionRequired && !opt.selected && module?.quantity >= maxAmount) ||
                                    (!selectionRequired && !opt.selected && module_outer_inner?.quantity >= maxAmount)
                                  }
                                  onClick={() => {
                                    moduleUpdate(module ? module : module_outer_inner, opt, index, 'select')
                                  }}
                                />
                                <div className="p-2">{opt.option?.name}</div>
                                {opt.option?.ingredients?.length > 0 && (
                                  <OverlayTrigger
                                    trigger="click"
                                    key="right"
                                    placement="right"
                                    overlay={
                                      <Popover id={`popover-positioned-right`}>
                                        <Popover.Body>
                                          {opt.option?.ingredients.map((ingredient, index) => {
                                            return <div key={index}>{ingredient.name}</div>
                                          })}
                                        </Popover.Body>
                                      </Popover>
                                    }
                                  >
                                    <button className={classnames('btn', styles.sizzlyColorHover)}>
                                      <FontAwesomeIcon icon={faInfoCircle} />
                                    </button>
                                  </OverlayTrigger>
                                )}
                                {!opt.modifierType &&
                                  !opt.priceModifier &&
                                  orderType === 'takeAway' &&
                                  opt.modifierTypeTakeAway &&
                                  opt.priceModifierTakeAway && (
                                    <div className="ms-auto p-2">
                                      {opt.modifierTypeTakeAway === 'surplus' ? '+' : '-'}
                                      {opt.priceModifierTakeAway.toFixed(2).replace('.', ',')} €
                                    </div>
                                  )}
                                {opt.modifierType && opt.priceModifier && (
                                  <div className="ms-auto p-2 w-50 text-end">
                                    {opt.modifierType === 'surplus' ? '+' : '-'}
                                    {orderType === 'takeAway' && opt.modifierTypeTakeAway && opt.priceModifierTakeAway
                                      ? opt.modifierTypeTakeAway === 'surplus'
                                        ? (opt.priceModifierTakeAway + opt.priceModifier).toFixed(2).replace('.', ',')
                                        : (-opt.priceModifierTakeAway + opt.priceModifier).toFixed(2).replace('.', ',')
                                      : opt.priceModifier.toFixed(2).replace('.', ',')}{' '}
                                    €
                                  </div>
                                )}
                              </label>
                              {opt.maxAmount && opt.maxAmount > 1 && opt.selected && (
                                <div className={classnames('', styles.sizzlyColor)}>
                                  <button
                                    className={classnames('btn', styles.sizzlyColorHover)}
                                    onClick={() => {
                                      moduleUpdate(module ? module : module_outer_inner, opt, index, 'decrease')
                                    }}
                                    // @ts-expect-error
                                    disabled={opt.option.quantity <= 1}
                                  >
                                    <FontAwesomeIcon icon={faCircleMinus} size={'lg'} />
                                  </button>
                                  <span className="align-middle">{opt.option.quantity}x</span>
                                  <button
                                    className={classnames('btn', styles.sizzlyColorHover)}
                                    onClick={() => {
                                      moduleUpdate(module ? module : module_outer_inner, opt, index, 'increase')
                                    }}
                                    disabled={
                                      // @ts-expect-error
                                      module ? module.quantity >= maxAmount : module_outer_inner.quantity >= maxAmount
                                    }
                                  >
                                    <FontAwesomeIcon icon={faCirclePlus} size={'lg'} />
                                  </button>
                                </div>
                              )}
                            </div>
                          )
                        }
                      })}
                    </div>
                  </div>
                )
              })}
          </div>
          <div>
            <div className="border-top border-3 container align-middle" />
            <div className="row justify-content-center">
              <span className={classnames(' col align-self-center w-25', styles.fontWeightBold)}>
                {t('restaurant:amount')}
              </span>
              <span
                className={classnames(' col-3 align-self-center text-center', styles.sizzlyColor, styles.amountDiv)}
              >
                {!disablePayment && (
                  <button
                    className={classnames('btn', styles.sizzlyColorHover)}
                    onClick={() => setItemQuantity(itemQuantity - 1)}
                    disabled={itemQuantity <= 1}
                  >
                    <FontAwesomeIcon icon={faCircleMinus} size={'lg'} />
                  </button>
                )}
                <span className="align-middle">{itemQuantity}x</span>
                {!disablePayment && (
                  <button
                    className={classnames('btn', styles.sizzlyColorHover)}
                    onClick={() => setItemQuantity(itemQuantity + 1)}
                    disabled={
                      itemQuantity >= 9 ||
                      (trackItemAmount &&
                        (availableItems ? availableItems : Infinity) -
                          itemQuantity -
                          calcLocalItemsAmount(
                            JSON.parse(localStorage.getItem('cart') as string),
                            selectedArticle.id
                          ) <=
                          0)
                    }
                  >
                    <FontAwesomeIcon icon={faCirclePlus} size={'lg'} />
                  </button>
                )}
              </span>

              <span
                className={classnames(
                  'align-self-center col text-center w-25',
                  styles.sizzlyColor,
                  styles.fontWeightBold
                )}
              >
                {(orderType !== 'takeAway'
                  ? itemQuantity * itemPrice
                  : itemQuantity * itemPrice + itemQuantity * takeAwayModifier
                )
                  .toFixed(2)
                  .replace('.', ',')}{' '}
                €
              </span>
            </div>
            <div className="border-top border-3" />
            {!disableComments && !disablePayment && (
              <FloatingLabel controlId="floatingInputGrid" label={t('restaurant:placeholder')}>
                <Form.Control
                  className="w-100 mt-3 border"
                  onChange={(e) => setComment(e.target.value)}
                  maxLength={200}
                  value={comment}
                />
              </FloatingLabel>
            )}
            {restaurantMisMatch && (
              <div className="alert alert-danger my-3" role="alert">
                {t('restaurant:shoppingBagMismatch')}
              </div>
            )}
          </div>
        </Modal.Body>
        {!!!disablePayment && (
          <Modal.Footer className={classnames(styles.footerHeight, styles.backgroundColor, 'p-0 py-1')}>
            <div className="w-100 text-center border-2">
              <button
                className={classnames(
                  'btn text-center text-white text-uppercase w-75',
                  restaurantMisMatch ? styles.orderButtonDanger : styles.orderButton
                )}
                onClick={() => {
                  addToCart()
                }}
              >
                <FontAwesomeIcon icon={faBasketShopping} />{' '}
                {restaurantMisMatch ? t('restaurant:newShoppingBag') : t('restaurant:addToCart')}
                {': '}
                {(orderType !== 'takeAway'
                  ? itemQuantity * itemPrice
                  : itemQuantity * itemPrice + itemQuantity * takeAwayModifier
                )
                  .toFixed(2)
                  .replace('.', ',')}{' '}
                €
              </button>
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </>
  )
}

export default ArticleModal
